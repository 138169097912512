import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './MyBotHistoryChart.scss';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { setLoadingFullAction } from '../../store/loading/loadingActions';

function MyBotHistoryChart(props) {
  const {
    myBotHistoryData,
    setLoadingFull,
  } = props;


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!myBotHistoryData) {
      setLoading(true);
      setLoadingFull({ isLoading: true, loadingText: 'Loading Bot History Data...' });
      setTimeout(() => {
        setLoading(false);
        setLoadingFull({ isLoading: false, loadingText: '' });
      }, 10000);
    } else {
      setLoading(false);
      setLoadingFull({ isLoading: false, loadingText: '' });
    }
  }, [myBotHistoryData, setLoadingFull]);

  useEffect(() => {
    console.log(myBotHistoryData);
  }, [myBotHistoryData]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}-${day}`;
  };
  const formatValue = (value) => {
    return value?.toLocaleString('tr-TR');
  };
  console.log(myBotHistoryData)
  const filteredData = myBotHistoryData ? 
  myBotHistoryData.filter(item => item.status !== "Open") : [];
  return (
    <> 
    {filteredData && filteredData.length > 0 
    && filteredData[0]?.status_code !== 400 ? (
      <LineChart
      width={600}
      height={300}
      data={filteredData.map((item, index) => ({ ...item, index: index + 1 }))}
      margin={{ top: 5, right: 30, left: 50, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="index" label={{ value: 'Index', position:
       'insideBottom',dx: 0, dy: 5  }} />
      <YAxis
        label={{ value: 'Profit/Loss (%)', angle: -90, position: 'inside', dx: -20, dy: 0 }}
      />
      <Tooltip formatter={(value) => formatValue(value)} />
      <Line type="monotone" dataKey="profit_loss_percentage"
       stroke="#8884d8" activeDot={{ r: 8 }} />
    </LineChart>
    
      ) : (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
        No Bot history found.
      </div>
      )}
  </>

  );
}

const mapStateToProps = (state) => {
  return {
    myBotHistoryData: state.botReducer.myBotHistoryData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBotHistoryChart);
