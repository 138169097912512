import store from '../store';
import * as userActionTypes from '../store/account/userActionTypes';
import * as alertActionTypes from '../store/alert/alertActionTypes';

export function errorHandler(error, history, href) {
  // Check if the error is due to invalid token
  if (error.message === 'Invalid token' || error?.response?.status === 401 || 403) {
    store.dispatch({ type: userActionTypes.LOGOUT_REQUEST });

    if (history && href) {
      history.push(href);
    } else if (!history && href) {
      window.location.href = href;
    }

    store.dispatch({
      type: alertActionTypes.SET_ALERT,
      msg: {
        title: 'Session Expired!',
        text: 'User session expired, Please login again.',
        variant: 'warning',
        outTimeMS: 6000,
      },

    }
    )
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);;
  } else {
    store.dispatch({
      type: alertActionTypes.SET_ALERT,
      msg: {
        title: 'Error!',
        text: error.msg || 'An unknown error occurred.',
        variant: 'danger',
        outTimeMS: 6000,
      },
    });
  }
}
