import React from 'react';
import './Wallet.scss';
import { Button, Container } from 'react-bootstrap';
import WalletHistoryTable from '../../components/WalletHistoryTable/WalletHistoryTable';

function Wallet({ ...props }) {

  return (
    <Container>
      <div className="text-fs-head-sm text-start">Wallet</div>
      <div className="border-0 p-4 shadow mt-4 position-relative">
        <div>Total Wallet Balance</div>
        <div>0 TL</div>
        <Button className='m-4 position-absolute top-0 end-0'>Load Balance To The Wallet</Button>
      </div>
      <div className="text-fs-head-sm mt-4 text-start">Wallet History</div>
      <WalletHistoryTable />
    </Container>
  );
}

export default Wallet;
