import React from 'react';
import './MyBotHistory.scss';
import { Form,Container } from 'react-bootstrap';
import MyBotHistoryTable from '../../components/MyBotHistoryTable/MyBotHistoryTable';
import MyBotHistoryChart from '../../components/MyBotHistoryChart/MyBotHistoryChart';
import {
  getMyBotHistoryRequestAction,
  sortMyBotHistorySortData,
  sortingMyBotHistoryAction,
  getBotRequestAction,
} from '../../store/bot/botActions';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';


function MyBotHistory({ ...props }) {
  const {
    getMyBotHistoryRequest,
    myBotHistorySortData,
    setSortData,
    sortingMyBotHistory,
    getMyBotHistoryRequestAction,
    getBotRequest,
    botData,
  } = props;

  const [selectedBotId, setSelectedBotId] = useState(1);
  useEffect(() => {
    getBotRequest();
  }, [getBotRequest]);

  useEffect(() => {
    const botArray = botData ? Object.values(botData) : [];
    if (botArray.length > 0) {
      setSelectedBotId(botArray[0].id);
    }
  }, [botData]);

  useEffect(() => {
    if (selectedBotId) {
      const payload = { id: selectedBotId };
      getMyBotHistoryRequestAction(payload);
    }
  }, [selectedBotId, getMyBotHistoryRequestAction]);
  // useEffect(() => {
  //   // getMyBotHistoryRequest();
  // }, []);
  function changeSortType(sortType) {
    const newBotHistorySortData = { ...myBotHistorySortData, sortType };
    setSortData(newBotHistorySortData);
    sortingMyBotHistory();
  }

  function changeSortKey(sortKey) {
    const newBotHistorySortData = { ...myBotHistorySortData, sortKey };
    setSortData(newBotHistorySortData);
    sortingMyBotHistory();
  }
  const botArray = botData ? Object.values(botData) : [];
  return (
    <Container>
      <div className="text-fs-head-sm text-start">My Bot History</div>
      <Container className='chart-container'><MyBotHistoryChart /></Container>
      {/* Bot selection radio buttons */}
      {botArray.length > 0 && (
        <Form className='mt-4'>
          {botArray.map(bot => (
            <Form.Check
              type="radio"
              key={bot.id}
              label={bot.name}
              name="botSelection"
              value={bot.id}
              checked={selectedBotId === bot.id}
              onChange={() => setSelectedBotId(bot.id)}
            />
          ))}
        </Form>
      )}
      {/* <div className="text-fs-head-sm mt-4 text-start">Bot History</div> */}
      <MyBotHistoryTable />
    </Container>
  );
}



const mapStateToProps = (state) => {
  return {
    myBotHistoryData: state.botReducer.myBotHistoryData,
    botStatisticsData: state.botReducer.botStatisticsData,
    myBotHistorySortData: state.botReducer.myBotHistorySortData,
    isLoading: state.loadingReducer.isLoading,
    user: state.userReducer.user,
    botData: state.botReducer.botData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMyBotHistoryRequest: (payload) => {
      dispatch(getMyBotHistoryRequestAction(payload));
    },
    setSortData: (payload) => {
      dispatch(sortMyBotHistorySortData(payload));
    },
    sortingMyBotHistory: (payload) => {
      dispatch(sortingMyBotHistoryAction(payload));
    },
    getMyBotHistoryRequestAction: (creds) => {
      dispatch(getMyBotHistoryRequestAction(creds));
    },
    getBotRequest: (payload) => {
      dispatch(getBotRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBotHistory);

