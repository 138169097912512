
import React, { useEffect, useState } from 'react';
import { getProjects } from '../../store/project/projectActions';
import { connect } from 'react-redux';
import MercurXQuickHandler from '../../components/MercurXQuickHandler/MercurXQuickHandler';
import { Container} from 'react-bootstrap';
import './BuyPackage.scss';
import { quickHandlerImages } from '../../helpers/quickHandlerImages';
// import TripleCard from '../../components/MercurXTripleCard/TripleCard';
// import Subscribe from '../../components/MercurXSubscribe/Subscribe';
// import { tripleCardInfo } from '../../helpers/tripleCardInfo';

function BuyPackage({ ...props }) {
  const { getProjects, projects, activeProjects, completedProjects } = props;

  useEffect(() => {
    getProjects();
  }, []);

  const maxRowCountPerPage = 9;
  const maxShowingPage = 5;
  const [activeCurrentPage, setActiveCurrentPage] = useState(0);
  const [activePages, setActivePages] = useState([]);
  const [activeRenderedProjects, setActiveRenderedProjects] = useState([]);
  const [activeRenderedPages, setActiveRenderedPages] = useState([]);
  const [activeLastPageOfPagination, setActiveLastPageOfPagination] = useState(maxShowingPage);

  const [completedCurrentPage, setCompletedCurrentPage] = useState(0);
  const [completedPages, setCompletedPages] = useState([]);
  const [completedRenderedProjects, setCompletedRenderedProjects] = useState([]);
  const [completedRenderedPages, setCompletedRenderedPages] = useState([]);
  const [completedLastPageOfPagination, setCompletedLastPageOfPagination] =
    useState(maxShowingPage);

  useEffect(() => {
    if (activeProjects?.length) {
      setActiveCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(activeProjects.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setActivePages([...tempPages]);
      setActiveRenderedPages([
        ...tempPages.slice(activeLastPageOfPagination - maxShowingPage, activeLastPageOfPagination),
      ]);
    }
  }, [activeProjects]);

  useEffect(() => {
    if (completedProjects?.length) {
      setCompletedCurrentPage(1);
      const tempPages = [];
      for (
        let index = 0;
        index < Math.ceil(completedProjects.length / maxRowCountPerPage);
        index++
      ) {
        tempPages.push(index + 1);
      }
      setCompletedPages([...tempPages]);
      setCompletedRenderedPages([
        ...tempPages.slice(
          completedLastPageOfPagination - maxShowingPage,
          completedLastPageOfPagination,
        ),
      ]);
    }
  }, [completedProjects]);

  useEffect(() => {
    if (activeProjects?.length && activeCurrentPage) {
      const firstIndex = maxRowCountPerPage * activeCurrentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * activeCurrentPage;
      const tempRendered = activeProjects?.slice(firstIndex, lastIndex);
      setActiveRenderedProjects([...tempRendered]);
    }
  }, [activeCurrentPage, activeProjects]);

  useEffect(() => {
    if (completedProjects?.length && completedCurrentPage) {
      const firstIndex = maxRowCountPerPage * completedCurrentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * completedCurrentPage;
      const tempRendered = completedProjects?.slice(firstIndex, lastIndex);
      setCompletedRenderedProjects([...tempRendered]);
    }
  }, [completedCurrentPage, completedProjects]);

  const setPaginationPages = (page, isActive) => {
    if (isActive === 'active') {
      if (
        activeRenderedPages.findIndex((p) => p === page + 1) === -1 &&
        activePages.slice(
          activeLastPageOfPagination + 1 - maxShowingPage,
          activeLastPageOfPagination + 1,
        ).length === maxShowingPage
      ) {
        setActiveLastPageOfPagination(activeLastPageOfPagination + 1);
        setActiveRenderedPages(
          activePages.slice(
            activeLastPageOfPagination + 1 - maxShowingPage,
            activeLastPageOfPagination + 1,
          ),
        );
      } else if (
        activeRenderedPages.findIndex((p) => p === page - 1) === -1 &&
        activePages.slice(
          activeLastPageOfPagination - 1 - maxShowingPage,
          activeLastPageOfPagination - 1,
        ).length === maxShowingPage
      ) {
        setActiveLastPageOfPagination(activeLastPageOfPagination - 1);
        setActiveRenderedPages(
          activePages.slice(
            activeLastPageOfPagination - 1 - maxShowingPage,
            activeLastPageOfPagination - 1,
          ),
        );
      }
    } else if (isActive === 'completed') {
      if (
        completedRenderedPages.findIndex((p) => p === page + 1) === -1 &&
        completedPages.slice(
          completedLastPageOfPagination + 1 - maxShowingPage,
          completedLastPageOfPagination + 1,
        ).length === maxShowingPage
      ) {
        setCompletedLastPageOfPagination(completedLastPageOfPagination + 1);
        setCompletedRenderedPages(
          completedPages.slice(
            completedLastPageOfPagination + 1 - maxShowingPage,
            completedLastPageOfPagination + 1,
          ),
        );
      } else if (
        completedRenderedPages.findIndex((p) => p === page - 1) === -1 &&
        completedPages.slice(
          completedLastPageOfPagination - 1 - maxShowingPage,
          completedLastPageOfPagination - 1,
        ).length === maxShowingPage
      ) {
        setCompletedLastPageOfPagination(completedLastPageOfPagination - 1);
        setCompletedRenderedPages(
          completedPages.slice(
            completedLastPageOfPagination - 1 - maxShowingPage,
            completedLastPageOfPagination - 1,
          ),
        );
      }
    }
  };

  const changePage = (page, isActive) => {
    if (isActive === 'active') {
      setActiveCurrentPage(page);
      if (page === activeLastPageOfPagination) {
        setPaginationPages(page, isActive);
      } else if (page === activeLastPageOfPagination - maxShowingPage + 1) {
        setPaginationPages(page, isActive);
      }
    } else if (isActive === 'completed') {
      setCompletedCurrentPage(page);
      if (page === completedLastPageOfPagination) {
        setPaginationPages(page, isActive);
      } else if (page === completedLastPageOfPagination - maxShowingPage + 1) {
        setPaginationPages(page, isActive);
      }
    }
  };

  const firstPage = (isActive) => {
    if (isActive === 'active') {
      setActiveCurrentPage(1);
      setActiveLastPageOfPagination(maxShowingPage);
      setActiveRenderedPages(activePages.slice(0, maxShowingPage));
    } else if (isActive === 'completed') {
      setCompletedCurrentPage(1);
      setCompletedLastPageOfPagination(maxShowingPage);
      setCompletedRenderedPages(completedPages.slice(0, maxShowingPage));
    }
  };

  const prevPage = (isActive) => {
    if (isActive === 'active') {
      if (activeCurrentPage - 1 !== 0) {
        setActiveCurrentPage(activeCurrentPage - 1);
        setPaginationPages(activeCurrentPage - 1, isActive);
      }
    } else if (isActive === 'completed') {
      if (completedCurrentPage - 1 !== 0) {
        setCompletedCurrentPage(completedCurrentPage - 1);
        setPaginationPages(completedCurrentPage - 1, isActive);
      }
    }
  };

  const nextPage = (isActive) => {
    if (isActive === 'active') {
      if (activeCurrentPage + 1 <= activePages.length) {
        setActiveCurrentPage(activeCurrentPage + 1);
        setPaginationPages(activeCurrentPage + 1, isActive);
      }
    } else if (isActive === 'completed') {
      if (completedCurrentPage + 1 <= completedPages.length) {
        setCompletedCurrentPage(completedCurrentPage + 1);
        setPaginationPages(completedCurrentPage + 1, isActive);
      }
    }
  };

  const lastPage = (isActive) => {
    if (isActive === 'active') {
      setActiveCurrentPage(activePages.length);
      if (activePages.length > maxShowingPage) {
        setActiveLastPageOfPagination(activePages.length);
        setActiveRenderedPages(
          activePages.slice(activePages.length - maxShowingPage, activePages.length),
        );
      }
    } else if (isActive === 'completed') {
      setCompletedCurrentPage(completedPages.length);
      if (completedPages.length > maxShowingPage) {
        setCompletedLastPageOfPagination(completedPages.length);
        setCompletedRenderedPages(
          completedPages.slice(completedPages.length - maxShowingPage, completedPages.length),
        );
      }
    }
  };

  return (
    <Container>
      <br></br>

      <div className="completed-sales-div align-items-center justify-content-center">
        <div>
        <div className="text-fs-head-sm text-start">Buy Package</div>
          <div
            className="d-flex align-items-center justify-content-center text-fs-body-lg 
        text-t-body-color mb-3 completed-sales-description"
          >
          </div>
        </div>
        <MercurXQuickHandler
          isSignUpAndKYC={true}
          signUpAndKYCImg={quickHandlerImages.lpSignUpAndKYCImg}
          isVerifyWallet={true}
          verifyWalletImg={quickHandlerImages.lpVerifyWalletImg}
          isStakeMerxToken={true}
          stakeMerxTokenImg={quickHandlerImages.lpStakeMerxTokenImg}
          registerForSaleImg={quickHandlerImages.lpRegisterForProject}
          href="#launchpad-projects"
          history={props.history}
        />
        {/* <TripleCard
          cardCount={3}
          firstCard={tripleCardInfo.lp1stCard}
          secondCard={tripleCardInfo.lp2ndCard}
          thirdCard={tripleCardInfo.lp3rdCard}
        /> */}
        {/* <Subscribe /> */}
      </div>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    activeProjects: state.projectReducer.activeProjects,
    completedProjects: state.projectReducer.completedProjects,
    projects: state.projectReducer.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (payload) => {
      dispatch(getProjects(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyPackage);
