import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './botActionTypes';
import * as actions from './botActions';
// import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';

function* getBotSaga(action) {
  try {
    const { data } = yield call(endpoints.getBot, action.payload);
    yield put(actions.getBotDataAction(data));
  } catch (e) {
    // yield put(
    //   alert.setAlertAction({
    //     title: 'Error!',
    //     text: 'Something went wrong when calling bot service.',
    //     color: 'danger',
    //     outTimeMS: 6000,
    //   }),
    // );
    yield put(actions.getBotErrorAction(e));
  }
}

function* getBotByIdSaga(action) {
  try {
    const { data } = yield call(endpoints.getBotById, action.payload.bot_id);
    yield put(actions.getBotByIdDataAction(data));
  } catch (e) {
    // yield put(
    //   alert.setAlertAction({
    //     title: 'Error!',
    //     text: 'Something went wrong when calling bot service.',
    //     color: 'danger',
    //     outTimeMS: 6000,
    //   }),
    // );
    yield put(actions.getBotByIdErrorAction(e));
  }
}
function* getRegisteredBotSaga(action) {
  try {
    console.log(action)
    const { data } = yield call(endpoints.getRegisteredBot, action.payload);
    yield put(actions.getRegisteredBotDataAction(data));
    console.log('data')
    console.log(data)
  } catch (e) {
    // yield put(
    //   alert.setAlertAction({
    //     title: 'Error!',
    //     text: 'Something went wrong when calling bot service.',
    //     color: 'danger',
    //     outTimeMS: 6000,
    //   }),
    // );
    yield put(actions.getRegisteredBotErrorAction(e));
  }
}
function* registerSaga({ creds }) {
  try {
    console.log(creds)
    const { data } = yield call(endpoints.register, creds);
    console.log(data)
    yield put(actions.registerData(data));
  } catch (e) {
    yield put(actions.registerError(e));
  }
}
function* registerUpdateSaga({ creds }) {
  try {
    console.log(creds)
    const { data } = yield call(endpoints.registerUpdate, creds);
    console.log(data)
    yield put(actions.registerUpdateData(data));
  } catch (e) {
    yield put(actions.registerUpdateError(e));
  }
}
function* postBotHistorySaga(payload) {
  try {
    console.log('postBotHistorySaga')
    console.log(payload)
    const { data } = yield call(endpoints.postBotHistory, payload.payload.bot_id );
    console.log(data)
    yield put(actions.getBotHistoryDataAction(data));
  } catch (e) {
    // yield put(
      // alert.setAlertAction({
      //   title: 'Error!',
      //   text: 'Something went wrong when calling lock history service.',
      //   color: 'danger',
      //   outTimeMS: 6000,
      // }),
    // );
    yield put(actions.getBotHistoryErrorAction(e));
  }
}
function* postMyBotHistorySaga(payload) {
  try {
    console.log(payload)
    console.log(payload.payload.id)
    const { data } = yield call(endpoints.postMyBotHistory, payload.payload.id);
    console.log(data)
    yield put(actions.getMyBotHistoryDataAction(data));
  } catch (e) {
     yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: 'Something went wrong when calling lock history service.',
        color: 'danger',
        outTimeMS: 6000,
      }),
     );
    yield put(actions.getMyBotHistoryErrorAction(e));
  }
}
//Watcher Sagas
function* watchGetBot() {
  yield takeEvery(types.GET_BOT_REQUEST, getBotSaga);
}
function* watchGetBotById() {
  yield takeEvery(types.GET_BOT_BY_ID_REQUEST, getBotByIdSaga);
}
function* watchGetRegisteredBot() {
  yield takeEvery(types.GET_REGISTERED_BOT_REQUEST, getRegisteredBotSaga);
}
function* watchRegister() {
  yield takeEvery(types.REGISTER_REQUEST, registerSaga);
}
function* watchRegisterUpdate() {
  yield takeEvery(types.REGISTER_UPDATE_REQUEST, registerUpdateSaga);
}
function* watchPostBotHistory() {
  yield takeEvery(types.GET_BOT_HISTORY_REQUEST, postBotHistorySaga);
}
function* watchPostMyBotHistory() {
  yield takeEvery(types.GET_MY_BOT_HISTORY_REQUEST, postMyBotHistorySaga);
}



export function* botSaga() {
  yield all([watchGetBot(),watchGetBotById(),
    watchGetRegisteredBot(),watchRegister(),watchPostBotHistory(),
    watchRegisterUpdate(),watchPostMyBotHistory()]);
}
