import * as types from './apiKeyActionTypes';


/**
 *
 
 */
export const apiKeyRequest = (creds) => {
  
  return {
    
    type: types.APIKEY_REQUEST,
    creds,
  };
};
/**
 *
 
 */
export const apiKeyData = (payload) => {
  return {
    type: types.APIKEY_DATA,
    payload,
  };
};
export const apiKeyError = (payload) => {
  return {
    type: types.APIKEY_ERROR,
    payload,
  };
};
/**
 *
 
 */
export const apiKeyDeleteRequest = (creds) => {
  
  return {
    
    type: types.APIKEY_DELETE_REQUEST,
    creds,
  };
};
/**
 *
 
 */
export const apiKeyDeleteData = (payload) => {
  return {
    type: types.APIKEY_DELETE_DATA,
    payload,
  };
};
export const apiKeyDeleteError = (payload) => {
  return {
    type: types.APIKEY_DELETE_ERROR,
    payload,
  };
};
