/* eslint-disable max-len */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import wallet from '../../helpers/wallet';
import { logoutRequestAction } from '../../store/account/userActions';
import {
  walletAccountDetailModalAction,
  WalletConnectModalAction,
} from '../../store/wallet/walletActions';
// eslint-disable-next-line max-len
import MercurXWalletAccountDetailModal from '../MercurXWalletAccountDetailModal/MercurXWalletAccountDetailModal';
// eslint-disable-next-line max-len
import MercurXWalletAccountHistoryModal from '../MercurXWalletAccountHistoryModal/MercurXWalletAccountHistoryModal';
import MercurXWalletConnectModal from '../MercurXWalletConnectModal/MercurXWalletConnectModal';
import { createBrowserHistory } from 'history';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import './MercurXNavbar.scss';
import { useEffect } from 'react';
// import mercurxLogoWhite from '../../assets/img/logo/mercurx-logo-white.png';
import multisignalsLogo from '../../assets/img/logo/logo.png';
import { mainColors } from '../../helpers/colors';
// import BannerHome from '../MercurXBanner/MercurXBannerHome/BannerHome';
// import BannerLaunchpad from '../MercurXBanner/MercurXBannerLaunchpad/BannerLaunchpad';
// import BannerSales from '../MercurXBanner/MercurXBannerSales/BannerSales';
// import BannerToken from '../MercurXBanner/MercurXBannerToken/BannerToken';
// eslint-disable-next-line max-len
// import BannerUserProfileDetail from '../MercurXBanner/MercurXBannerUserProfileDetail/BannerUserProfileDetail';
// // eslint-disable-next-line max-len
// import BannerUserProfileDetailVerify from '../MercurXBanner/MercurXBannerUserProfileDetail/BannerUserProfileDetailVerify';
// // eslint-disable-next-line max-len
// import BannerUserProfileDetailKYC from '../MercurXBanner/MercurXBannerUserProfileDetail/BannerUserProfileDetailKYC';
// import BannerStats from '../MercurXBanner/MercurXBannerStats/BannerStats';
// import BannerFaq from '../MercurXBanner/MercurXBannerFaq/BannerFaq';
// // eslint-disable-next-line max-len
// import BannerTermsOfService from '../MercurXBanner/MercurXBannerTermsOfService/BannerTermsOfService';
// import BannerPrivacyPolicy from '../MercurXBanner/MercurXBannerPrivacyPolicy/BannerPrivacyPolicy';
// // eslint-disable-next-line max-len
// import BannerProjectInformation from '../MercurXBanner/MercurXBannerProjectInformation/BannerProjectInformation';
// import BannerStaking from '../MercurXBanner/MercurXBannerStaking/BannerStaking';
import { Popover } from 'react-tiny-popover';
import { Col, Row } from 'react-bootstrap';
// import BannerAbout from '../MercurXBanner/MercurXBannerAbout/BannerAbout';
// import BannerWhitepaper from '../MercurXBanner/MercurXBannerWhitepaper/BannerWhitepaper';
import { getTierRequestAction } from '../../store/tier/tierActions';

function Navigation({ ...props }) {
  const {
    user,
    accounts,
    balance_,
    walletAccountDetailModalRequest,
    logoutRequest,
    WalletConnectModalRequest,
    token,
    project,
    history,
    getTierRequest,
    tierData,
    MainLayoutStatus,
    isPublic,
  } = props;
  const pathname = history?.location.pathname;
  const pathnameHash = history?.location.hash;

  const [profileOpen, setProfileOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const { utils } = require('ethers');


  useEffect(() => {
    if (accounts?.[0]) {
      wallet.getMyBalance('0x0f1C0c3435e866ec002BbfA1C30cb867453b8920');
      const address = utils.getAddress(accounts?.[0]);
      const payload = {
        id: address,
      };
      getTierRequest(payload);
    }
  }, [accounts]);

  const handleLogout = () => {
    logoutRequest();
  };
  const pathIsActive = (path) => {
    const history = createBrowserHistory();
    const pathname = history.location.pathname.split('/')[1];

    if (pathname?.toLowerCase() === path.toLowerCase()) {
      return true;
    }
    return false;
  };
  const handleShow = () => {
    walletAccountDetailModalRequest(true);
  };
  const handleShowWallet = () => {
    WalletConnectModalRequest(true);
  };
  // const BannerReturn = () => {
  //   if (pathname?.toLowerCase() === '/') {
  //     return <BannerHome />;
  //   } else if (pathname?.toLowerCase() === '/launchpad') {
  //     return <BannerLaunchpad />;
  //   } else if (pathname?.toLowerCase() === '/sales') {
  //     return <BannerSales {...props} />;
  //   } else if (pathname?.toLowerCase() === '/staking') {
  //     return <BannerStaking {...props} />;
  //   } else if (pathname?.toLowerCase().split('/')[1] === 'project') {
  //     return <BannerToken />;
  //   } else if (pathname?.toLowerCase() === '/profile') {
  //     if (pathnameHash?.toLowerCase() === '#profile') {
  //       return <BannerUserProfileDetail />;
  //     } else if (pathnameHash?.toLowerCase() === '#verify') {
  //       return <BannerUserProfileDetailVerify />;
  //     } else if (pathnameHash?.toLowerCase() === '#kyc') {
  //       return <BannerUserProfileDetailKYC />;
  //     } else {
  //       return <BannerUserProfileDetail />;
  //     }
  //   } else if (pathname?.toLowerCase() === '/about') {
  //     return <BannerAbout />;
  //   } else if (pathname?.toLowerCase() === '/whitepaper') {
  //     return <BannerWhitepaper />;
  //   } else if (pathname?.toLowerCase() === '/stats') {
  //     return <BannerStats />;
  //   } else if (pathname?.toLowerCase() === '/faq') {
  //     return <BannerFaq />;
  //   } else if (pathname?.toLowerCase() === '/termsofservice') {
  //     return <BannerTermsOfService />;
  //   } else if (pathname?.toLowerCase() === '/privacypolicy') {
  //     return <BannerPrivacyPolicy />;
  //   } else if (pathname?.toLowerCase() === '/projectinformation') {
  //     return <BannerProjectInformation />;
  //   } else {
  //     return '';
  //   }
  // };
  function calculateBalance() {
    const formatBalance = (Math.floor((Number(balance_) / 10000) * 100) / 100)
      .toString()
      .split('.');
    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || '0';
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  // const getBannerClassName = () => {
  //   if (MainLayoutStatus === 'True') {
  //     if (pathname?.toLowerCase() === '/') {
  //       return 'banner-design';
  //     } else if (pathname?.toLowerCase() === '/launchpad') {
  //       return 'banner-design-launchpad';
  //     } else {
  //       return 'banner-design-profile';
  //     }
  //   } else {
  //     return 'banner-public';
  //   }
  // };

  function getProfilePopoverContent() {
    return (
      <div className="bg-white rounded shadow p-1">
        <Link className="navbar-popover-link p-1 rounded d-block" to="/profile">
          Profile
        </Link>
        {/* <Link className="navbar-popover-link p-1 rounded d-block" to="/profile#kyc">
          KYC
        </Link>
        <Link className="navbar-popover-link p-1 rounded d-block" to="/profile#2fa">
          2FA
        </Link>
        <Link className="navbar-popover-link p-1 rounded d-block" to="/profile#verify">
          Verify Wallet
        </Link> */}
        <div className="navbar-popover-link p-1 rounded d-block" onClick={handleLogout}>
          Logout
        </div>
      </div>
    );
  }

  function getAboutPopoverContent() {
    return (
      <div className="about-popover-container bg-white rounded shadow p-2">
        <Row>
          <Col sm="12" md="6">
            <Link
              className="navbar-popover-link p-1 rounded d-block"
              to="/about"
              onClick={() => setAboutOpen(false)}
            >
              <div className="text-fs-head-xs">About MultiSignals</div>
              <div className="text-fs-body-sm text-t-body-color">
                Learn about the MultiSignals platform
              </div>
            </Link>
          </Col>
          <Col sm="12" md="6">
            {/* <Link
              className="navbar-popover-link p-1 rounded d-block"
              to="/whitepaper"
              onClick={() => setAboutOpen(false)}
            > */}
            <div
              className="navbar-popover-link p-1 rounded d-block"
              onClick={() => {
                // window.open('https://whitepaper.mercurx.com', '_blank');
                setAboutOpen(false);
              }}
            >
              <div className="text-fs-head-xs">Whitepaper</div>
              <div className="text-fs-body-sm text-t-body-color">
                The original whitepaper in multiple languages
              </div>
            </div>
            {/* </Link> */}
          </Col>
          <Col sm="12" md="6">
            <div
              className="navbar-popover-link p-1 rounded d-block"
              onClick={() => {
                window.open('https://docs.multisignals.com', '_blank');
                setAboutOpen(false);
              }}
            >
              <div className="text-fs-head-xs">User Guide</div>
              <div className="text-fs-body-sm text-t-body-color">
                Instructions and tips about MultiSignals
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div
    // className={getBannerClassName()}
    >
      <Navbar className="mercurx-navbar" expand="lg" style={{ zIndex: 1 }}>
        <Container>

          <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-white" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end flex-column align-items-end navbar-design"
            data-ispublic={isPublic}
          >
            <Nav
              className="ml-auto align-items-center py-3 pe-2"
              variant="pills"
              data-ispublic={isPublic}
            >
              {/* <Nav.Link
                as={Link}
                data-ispublic={isPublic}
                className={
                  'text-fs-head-xs px-2 public-nav' + (pathIsActive('launchpad') ? ' active' : '')
                }
                to="/launchpad"
              >
                Launchpad
              </Nav.Link> */}
              {/* <Nav.Link
                as={Link}
                data-ispublic={isPublic}
                className={
                  'text-fs-head-xs px-2 public-nav' + (pathIsActive('sales') ? ' active' : '')
                }
                to="/sales"
              >
                Sales
              </Nav.Link> */}
              {/* <Nav.Link
                as={Link}
                data-ispublic={isPublic}
                className={
                  'text-fs-head-xs text-muted px-2 public-nav' +
                  (pathIsActive('staking') ? ' active' : '')
                }
                to="/staking"
                // disabled={true}
              >
                Locking
              </Nav.Link> */}
              {/* <Nav.Link
                as={Link}
                data-ispublic={isPublic}
                className={
                  'text-fs-head-xs text-muted px-2 public-nav' +
                  (pathIsActive('airdrop') ? ' active' : '')
                }
                to="#"
                disabled={true}
              >
                Airdrop
              </Nav.Link> */}
              {isPublic && (
                <Navbar.Brand as={Link} to="/login">
                  <img
                    src={multisignalsLogo}
                    alt="Logo"
                    width={100}
                  />
                </Navbar.Brand>
              )}
              {/* <Nav.Link
                as={Link}
                data-ispublic={isPublic}
                className={
                  'text-fs-head-xs text-muted px-2 public-nav' +
                  (pathIsActive('buy-package') ? ' active' : '')
                }
                to="/buy-package"
                disabled={true}
              >
                + Buy Package
              </Nav.Link> */}
              {/* <Nav.Link
                as={Link}
                data-ispublic={isPublic}
                className={
                  'text-fs-head-xs text-muted px-5 public-nav' +
                  (pathIsActive('/general-settings/bot-management') ? ' active' : '')
                }
                to="/general-settings/bot-management"
              // disabled={true}
              >
                Default Bot: [{isPublic ? 'userBot' : 'unselected'}]
              </Nav.Link> */}
              <Popover
                isOpen={aboutOpen}
                padding={10}
                positions={['bottom']}
                content={getAboutPopoverContent()}
                onClickOutside={() => setAboutOpen(false)}
              >
                <Nav.Link
                  className={
                    'text-fs-head-xs text-muted px-2 public-nav' +
                    (pathIsActive('about') || pathIsActive('whitepaper') ? ' active' : '')
                  }
                  onClick={() => setAboutOpen(!aboutOpen)}
                >
                  <span className="d-flex align-items-center">
                    <span>About</span>
                    <MercurXIcons name={aboutOpen ? 'BiChevronUp' : 'BiChevronDown'} size={24} />
                  </span>
                </Nav.Link>
              </Popover>
              {!user && (
                <>
                  <Nav.Link
                    as={Link}
                    data-ispublic={isPublic}
                    className={
                      'text-fs-head-xs px-2 public-nav' + (pathIsActive('login') ? ' active' : '')
                    }
                    to="/login"
                  >
                    Login
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    data-ispublic={isPublic}
                    className={
                      'text-fs-head-xs px-2 public-nav' + (pathIsActive('signup') ? ' active' : '')
                    }
                    to="/signup"
                  >
                    Sign up
                  </Nav.Link>
                </>
              )}

              {user && (
                <Popover
                  isOpen={profileOpen}
                  padding={10}
                  positions={['bottom']}
                  content={getProfilePopoverContent()}
                  onClickOutside={() => setProfileOpen(false)}
                >
                  <div
                    className="bg-white py-1 px-2 m-1 shadow rounded cursor-pointer"
                    onClick={() => setProfileOpen(!profileOpen)}
                  >
                    <MercurXIcons name="MdPerson" color={mainColors['dark-light']} size="26" />
                  </div>
                </Popover>
              )}
            </Nav>
            {accounts?.[0] && (
              <div
                className={
                  'd-flex justify-content-center justify-content-lg-end rounded text-white ' +
                  'm-auto mx-lg-3' +
                  (balance_ !== null && balance_ !== undefined
                    ? ' bg-primary navbar-wallet-account-container'
                    : '')
                }
              >
                {balance_ !== null && balance_ !== undefined && (
                  <div
                    title={calculateBalance()}
                    className="d-none d-sm-flex align-items-center navbar-balance-button 
                bg-primary rounded px-2 justify-content-center"
                  >
                    <div className="navbar-balance-text text-truncate mr-1 py-1">
                      {' '}
                      {calculateBalance()}
                    </div>
                    <span>MERX</span>
                  </div>
                )}

                <div
                  title={accounts?.[0]}
                  className="navbar-account-button
                bg-white rounded text-tertiary text-fs-head-xxs d-flex align-items-center px-2"
                  onClick={handleShow}
                >
                  <span className="text-truncate me-1 ps-3 pe-2 py-1">{accounts?.[0]}</span>
                  <MercurXIcons name="BiWallet" color={mainColors['primary']} size="24" />
                </div>
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <MercurXWalletAccountDetailModal />
      <MercurXWalletAccountHistoryModal />
      <MercurXWalletConnectModal />
      {/* {BannerReturn()} */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
    token: state.tokenReducer.token,
    project: state.projectReducer.project,
    balance_: state.walletReducer.balance_,
    tierData: state.tierReducer.tierData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTierRequest: (payload) => {
      dispatch(getTierRequestAction(payload));
    },
    logoutRequest: (payload) => {
      dispatch(logoutRequestAction(payload));
    },
    walletAccountDetailModalRequest: (payload) => {
      dispatch(walletAccountDetailModalAction(payload));
    },
    WalletConnectModalRequest: (payload) => {
      dispatch(WalletConnectModalAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
