import { useEffect, useState } from 'react';
import { Badge,Pagination, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import './BotHistoryTable.scss';
function BotHistoryTable(props) {
  const {
    botHistoryData,
  } = props;
  const [txs, setTxs] = useState([]);
  // Şu anki yerel tarih ve saat
  console.log(botHistoryData)
  const maxRowCountPerPage = 5;
  const maxShowingPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);
  useEffect(() => {
    if (botHistoryData) {
      setCurrentPage(1);
      const tempPages = [];
      for (
        let index = 0;
        index < Math.ceil(botHistoryData.length / maxRowCountPerPage);
        index++
      ) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [botHistoryData]);

  console.log(renderedData)
  useEffect(() => {
    if (botHistoryData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = botHistoryData?.slice(firstIndex, lastIndex);
      setRenderedData([...tempRendered]);
    }
  }, [currentPage, botHistoryData]);

  function scrollToBotHistoryContainer() {
    const botHistory = document.getElementById('bot-history-container');
    if (botHistory) {
      botHistory.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToBotHistoryContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToBotHistoryContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToBotHistoryContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToBotHistoryContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToBotHistoryContainer();
  };
  console.log(renderedData)
  const formatValue = (value) => {
    return value?.toLocaleString('tr-TR');
  };
  return (
    <>
      <Table className="bot-history-table" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Token</th>
            <th>Buy Order Id</th>
            <th>Avg Buy Price</th>
            <th>Avg Sell Price</th>
            <th>Profit Loss</th>
            <th>Profit loss Percentage</th>
            <th>Sales Proceeds</th>
            <th>Sales Timestamp</th>
            <th>Total Buy Cost</th>
            <th>Total Buy Qty</th>
            <th>Total Sales Qty</th>
            <th>Status</th>
          </tr>
        </thead>
        {renderedData?.length && renderedData[0]?.status_code !== 400 ? (
          <tbody>
            {renderedData.map((item, index) => {
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr key={index} className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.token}</td>
                    <td>{item.buy_order_id}</td>
                    <td>{'$'+formatValue(item.avg_buy_price)}</td>
                    <td>{'$'+formatValue(item.avg_sell_price)}</td>
                    <td>{'$'+formatValue(item.profit_loss)}</td>
                    <td>{item.profit_loss_percentage}%</td>
                    <td>{'$'+formatValue(item.sales_proceeds)}</td>
                    <td>{item.sales_timestamp}</td>
                    <td>{'$'+formatValue(item.total_buy_cost)}</td>
                    <td>{formatValue(item.total_buy_qty)}</td>
                    <td>{formatValue(item.total_sales_qty)}</td>
                    <td>
                      {item.status ==="Open" ? (
                        <Badge bg="success">Open</Badge>
                      ) : (
                        <Badge bg="danger">Closed</Badge>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>

      {renderedData.length === 0 || renderedData[0].status_code === 400 ? (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No Bot history found.
        </div>
        ) : null}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BotHistoryTable);
