import React from 'react';
import './BotDetail.scss';
import { Container } from 'react-bootstrap';
import BotHistoryTable from '../../components/BotHistoryTable/BotHistoryTable';
import BotHistoryChart from '../../components/BotHistoryChart/BotHistoryChart';
import {
  getBotHistoryRequestAction,
  sortBotHistorySortData,
  sortingBotHistoryAction,
} from '../../store/bot/botActions';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
import { useParams,useLocation } from 'react-router-dom';

function BotDetail({ ...props }) {
  const {
    getBotHistoryRequest,
    botHistorySortData,
    setSortData,
    sortingBotHistory,
    getBotHistoryRequestAction,
  } = props;

  const { id } = useParams();
  const location = useLocation();
  const item = location.state?.item; 

  useEffect(() => {
    const bot_id = id;
    const payload = {
      bot_id
    };
    console.log(payload)
    getBotHistoryRequestAction(payload);
  }, [botHistorySortData]);
  useEffect(() => {
    getBotHistoryRequest();
  }, []);

  function changeSortType(sortType) {
    const newBotHistorySortData = { ...botHistorySortData, sortType };
    setSortData(newBotHistorySortData);
    sortingBotHistory();
  }

  function changeSortKey(sortKey) {
    const newBotHistorySortData = { ...botHistorySortData, sortKey };
    setSortData(newBotHistorySortData);
    sortingBotHistory();
  }

  return (
    <Container>
      <MercurXBackButton buttonText="Back" />
      <div className="text-fs-head-sm text-start">Bot Detail</div>
      <div className='bot-chart-container'><BotHistoryChart /></div>
      <div className="text-fs-head-sm mt-4 text-start">Bot History</div>
      <BotHistoryTable />
    </Container>
  );
}



const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
    botStatisticsData: state.botReducer.botStatisticsData,
    botHistorySortData: state.botReducer.botHistorySortData,
    isLoading: state.loadingReducer.isLoading,
    user: state.userReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBotHistoryRequest: (payload) => {
      dispatch(getBotHistoryRequestAction(payload));
    },
    setSortData: (payload) => {
      dispatch(sortBotHistorySortData(payload));
    },
    sortingBotHistory: (payload) => {
      dispatch(sortingBotHistoryAction(payload));
    },
    getBotHistoryRequestAction: (creds) => {
      dispatch(getBotHistoryRequestAction(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BotDetail);

