export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_DATA = 'LOGIN_DATA';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_DATA = 'SIGN_UP_DATA';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_DATA = 'FORGOT_PASSWORD_DATA';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_DATA = 'RESET_PASSWORD_DATA';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
export const ACTIVATION_DATA = 'ACTIVATION_DATA';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';

export const ACCOUNT_DETAILS_REQUEST = 'ACCOUNT_DETAILS_REQUEST';
export const ACCOUNT_DETAILS_DATA = 'ACCOUNT_DETAILS_DATA';
export const ACCOUNT_DETAILS_ERROR = 'ACCOUNT_DETAILS_ERROR';

export const ACCOUNT_VERIFIED = 'ACCOUNT_VERIFIED';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_DATA = 'LOGOUT_DATA';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const RESEND_VERIFICATION_EMAIL_REQUEST = 'RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_DATA = 'RESEND_VERIFICATION_EMAIL_DATA';
export const RESEND_VERIFICATION_EMAIL_ERROR = 'RESEND_VERIFICATION_EMAIL_ERROR';

export const CHECK_USER_TOKEN_REQUEST = 'CHECK_USER_TOKEN_REQUEST';
export const CHECK_USER_TOKEN_DATA = 'CHECK_USER_TOKEN_DATA';
export const CHECK_USER_TOKEN_ERROR = 'CHECK_USER_TOKEN_ERROR';

export const ENABLE_2FA_REQUEST = 'ENABLE_2FA_REQUEST';
export const ENABLE_2FA_DATA = 'ENABLE_2FA_DATA';
export const ENABLE_2FA_ERROR = 'ENABLE_2FA_ERROR';

export const GENERATE_QR_REQUEST = 'GENERATE_QR_REQUEST';
export const GENERATE_QR_DATA = 'GENERATE_QR_DATA';
export const GENERATE_QR_ERROR = 'GENERATE_QR_ERROR';

export const VERIFY_2FA_REQUEST = 'VERIFY_2FA_REQUEST';
export const VERIFY_2FA_DATA = 'VERIFY_2FA_DATA';
export const VERIFY_2FA_ERROR = 'VERIFY_2FA_ERROR';

export const CREATE_2FA_REQUEST = 'CREATE_2FA_REQUEST';
export const CREATE_2FA_DATA = 'CREATE_2FA_DATA';
export const CREATE_2FA_ERROR = 'CREATE_2FA_DATA';


export const UPDATE_USER_TWO_FACTOR_AUTH = 'UPDATE_USER_TWO_FACTOR_AUTH';
