import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './MercurXSidebarMenu.scss';
import { Link } from 'react-router-dom';
// import backOfficeMiniLogo from '../../assets/img/logo/mercurx-logo-mini.png';
import MultisignalsLogo from '../../assets/img/logo/logo.png';
import { useState } from 'react';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Navbar from 'react-bootstrap/Navbar';

function MercurXSidebarMenu({ ...props }) {
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const { pathname: activePath } = useLocation();
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  function controlPath(cPath) {
    if (activePath.startsWith(cPath)) {
      return true;
    }
    return false;
  }

  return (
    <Sidebar collapsed={collapsedMenu} className="bg-gray-300" backgroundColor="white" 
    style={{ height: '100vh', overflowY: 'auto' }}>
      <div className="d-flex align-items-center justify-content-center py-3 px-2" >
        <Navbar.Brand as={Link} to="/">
          <img
            src={MultisignalsLogo}
            alt="logo"
            height={60}
          />
        </Navbar.Brand>
        <span className="cursor-pointer" onClick={() => setCollapsedMenu(!collapsedMenu)}>
          <MercurXIcons name={collapsedMenu ? 'BsChevronRight' : 'BsChevronLeft'} size={20} />
        </span>
      </div>
      <Menu>
        <MenuItem
          icon={<MercurXIcons name="BsHouse" size={20} />}
          className={`${activePath === '/' ? 'active-menu-item' : ''}`}
          component={<Link to="/" />}
        >
          Home
        </MenuItem>
        {/* <MenuItem
          icon={<MercurXIcons name="BiCheckShield" size={20} />}
          className={`${activePath === '/wallet' ? 'active-menu-item' : ''}`}
          component={<Link to="/wallet" />}
        >
          Wallet
        </MenuItem> */}
        <MenuItem
         icon={<MercurXIcons name="BiHomeSmile" size={20} />}
         className={`${activePath === '/marketplace' ? 'active-menu-item' : ''}`}
         component={<Link to="/marketplace" />}
        >
          Marketplace
        </MenuItem>
        <MenuItem
         icon={<MercurXIcons name="BiBarChart" size={20} />}
         className={`${activePath === '/my-bot-history' ? 'active-menu-item' : ''}`}
         component={<Link to="/my-bot-history" />}
        >
          My Bot History
        </MenuItem>
        {/* <MenuItem
          icon={<MercurXIcons name="BiBook" size={20} />}
          className={`${activePath === '/academy' ? 'active-menu-item' : ''}`}
          component={<Link to="/academy" />}
        >
          Academy
        </MenuItem> */}
        <SubMenu
          label="General Settings"
          // icon={<MercurXIcons name="TbSettings" size={20} />}
          className={`${controlPath('/general-settings/api-settings') ||
           controlPath('/general-settings/bot-management') 
          || controlPath('/tokens') ? 'active-submenu' : ''
            }`}
          defaultOpen={controlPath('/general-settings/api-settings') ||
           controlPath('/general-settings/bot-management')
           ||
          controlPath('/tokens')}
        >
          <MenuItem
            icon={<MercurXIcons name="BsKey" size={20} />}
            className={`${controlPath('/general-settings/api-settings') ? 'active-menu-item' : ''}`}
            component={<Link to="/general-settings/api-settings" />}
          >
            API Settings
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="BsCoin" size={20} />}
            className={`${controlPath('/general-settings/bot-management')?'active-menu-item' : ''}`}
            component={<Link to="/general-settings/bot-management" />}
          >
            Bot Management
          </MenuItem>
          {/* <MenuItem
            icon={<MercurXIcons name="BsPeople" size={20} />}
            className={`${controlPath('/projects') ? 'active-menu-item' : ''}`}
            component={<Link to="/projects" />}
          >
            Traders
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="BsRobot" size={20} />}
            className={`${controlPath('/projects') ? 'active-menu-item' : ''}`}
            component={<Link to="/projects" />}
          >
            Trader Bots
          </MenuItem> */}
        </SubMenu>
        {/* <SubMenu
          label="Bot Settings"
          // icon={<MercurXIcons name="BsActivity" size={20} />}
          className={`${activePath === '/transactions' ||
            activePath === '/locks' ? 'active-submenu' : ''
              || activePath === '/backoffice-transactions' ? 'active-submenu' : ''
            }`}
          defaultOpen={activePath === '/transactions' || activePath === '/locks'
            || activePath === '/backoffice-transactions'}
        >
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-txn">Buying Strategy Designer</Tooltip>}
          >
            <MenuItem
              icon={<MercurXIcons name="BsCpu" size={20} />}
              className={`${activePath === '/transactions' ? 'active-menu-item' : ''}`}
              component={<Link to="/transactions" />}
            >
              Buying Strategy Designer
            </MenuItem>
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-boTxn">Sales Strategy Designer</Tooltip>}
          >
            <MenuItem
              icon={<MercurXIcons name="BsFileLock" size={20} />}
              className={`${activePath === '/locks' ? 'active-menu-item' : ''}`}
              component={<Link to="/locks" />}
            >
              Sales Strategy Designer
            </MenuItem>
          </OverlayTrigger>
          <MenuItem
            icon={<MercurXIcons name="BsListTask" size={20} />}
            className={`${activePath === '/backoffice-transactions' ? 'active-menu-item' : ''}`}
            component={<Link to="/backoffice-transactions" />}
          >
            Budget Settings
          </MenuItem>

        </SubMenu>
        <SubMenu
          label="Extra Operations"
          // icon={<MercurXIcons name="BsPerson" size={20} />}  
          className={`${activePath === '/launchpad-users' || activePath === '/backoffice-users'
            ? 'active-submenu'
            : ''
            }`}
          defaultOpen={activePath === '/launchpad-users' || activePath === '/backoffice-users'}
        >
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-txn">Manual Operations</Tooltip>}
          >
            <MenuItem
              icon={<MercurXIcons name="BsBarChart" size={20} />}
              className={`${activePath === '/launchpad-users' ? 'active-menu-item' : ''}`}
              component={<Link to="/launchpad-users" />}
            >
              Manual Operations
            </MenuItem>
          </OverlayTrigger>

          <MenuItem
            icon={<MercurXIcons name="BsBarChart" size={20} />}
            className={`${activePath === '/backoffice-users' ? 'active-menu-item' : ''}`}
            component={<Link to="/backoffice-users" />}
          >
            Real Operations
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="BsBarChart" size={20} />}
            className={`${activePath === '/backoffice-users' ? 'active-menu-item' : ''}`}
            component={<Link to="/backoffice-users" />}
          >
            Virtual Operations
          </MenuItem>
        </SubMenu> */}
        <MenuItem
          icon={<MercurXIcons name="BiBook" size={20} />}
          onClick={() => {
            window.open('https://docs.multisignals.com', '_blank');
          }}
          component={<Link to="/" />}
        >
          User Guide
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXSidebarMenu);
