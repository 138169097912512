import {
  BOT_HISTORY_KEYS,
  BOT_HISTORY_SORT_TYPES,
} from '../../pages/BotDetail/botDetailConstants';
import {
  MY_BOT_HISTORY_KEYS,
  MY_BOT_HISTORY_SORT_TYPES,
} from '../../pages/MyBotHistory/myBotHistoryConstants';
import * as types from './botActionTypes';

const initialState = {
  successStartStart: null,
  successUpdateStart: null,
  botData: null,
  botDataById: null,
  registeredBotData: null,
  botHistoryData: null,
  botHistorySortData: {
    sortType: BOT_HISTORY_SORT_TYPES.ASCENDING,
    sortKey: BOT_HISTORY_KEYS.NAME,
  },
  myBotHistoryData: null,
  myBotHistorySortData: {
    sortType: MY_BOT_HISTORY_SORT_TYPES.ASCENDING,
    sortKey: MY_BOT_HISTORY_KEYS.NAME,
  },
  error: {
    type: null,
    data: null,
  },
};

// Helper function to sort by sales_timestamp
const sortBySalesTimestamp = (data, sortType) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.sales_timestamp);
    const dateB = new Date(b.sales_timestamp);
    return sortType === BOT_HISTORY_SORT_TYPES.ASCENDING ? dateA - dateB : dateB - dateA;
  });
};

export const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOT_DATA:
      return {
        ...state,
        botData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BOT_BY_ID_DATA:
      return {
        ...state,
        botDataById: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_BOT_BY_ID_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.GET_REGISTERED_BOT_DATA:
      return {
        ...state,
        registeredBotData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_REGISTERED_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.REGISTER_DATA:
      return {
        ...state,
        successStart: action.successStart ? Object.assign({}, action.successStart) : null,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        error: {
          type: types.REGISTER_ERROR,
          data: action.payload,
        },
      };
    case types.REGISTER_UPDATE_DATA:
      return {
        ...state,
        successUpdate: action.successUpdate ? Object.assign({}, action.successUpdate) : null,
      };
    case types.REGISTER_UPDATE_ERROR:
      return {
        ...state,
        error: {
          type: types.REGISTER_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BOT_HISTORY_DATA:
      return {
        ...state,
        botHistoryData: action?.payload ? 
        sortBySalesTimestamp([...action?.payload], state.botHistorySortData.sortType) : null,
      };
    case types.GET_BOT_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_MY_BOT_HISTORY_DATA:
      return {
        ...state,
        myBotHistoryData: action?.payload ? 
        sortBySalesTimestamp([...action?.payload], state.myBotHistorySortData.sortType) : null,
      };
    case types.GET_MY_BOT_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_MY_BOT_HISTORY_ERROR,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
