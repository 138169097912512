import React from 'react';
import './BotManagementRegistered.scss';
import { Container } from 'react-bootstrap';
import BotRegisteredManagementTable from 
'../../components/BotRegisteredManagementTable/BotRegisteredManagementTable';
import {
  getRegisteredBotRequestAction,
  sortBotHistorySortData,
  sortingBotHistoryAction,
} from '../../store/bot/botActions';
import { connect } from 'react-redux';
import { useEffect } from 'react';

function BotManagementRegistered({ ...props }) {
  const {
    botHistorySortData,
    setSortData,
    sortingBotHistory,
    registeredBotData,
    getRegisteredBotRequest,
  } = props;

  useEffect(() => {
    getRegisteredBotRequestAction();
  }, [botHistorySortData]);
  useEffect(() => {
    getRegisteredBotRequest();
  }, []);

  function changeSortType(sortType) {
    const newBotHistorySortData = { ...botHistorySortData, sortType };
    setSortData(newBotHistorySortData);
    sortingBotHistory();
  }

  function changeSortKey(sortKey) {
    const newBotHistorySortData = { ...botHistorySortData, sortKey };
    setSortData(newBotHistorySortData);
    sortingBotHistory();
  }
  
  return (
    <Container>
      <div className="text-fs-head-sm text-start">Registered Bot Management</div>
      <BotRegisteredManagementTable />
    </Container>
  );
}



const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
    botStatisticsData: state.botReducer.botStatisticsData,
    botHistorySortData: state.botReducer.botHistorySortData,
    isLoading: state.loadingReducer.isLoading,
    user: state.userReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSortData: (payload) => {
      dispatch(sortBotHistorySortData(payload));
    },
    sortingBotHistory: (payload) => {
      dispatch(sortingBotHistoryAction(payload));
    },
    getRegisteredBotRequestAction: (payload) => {
      dispatch(getRegisteredBotRequestAction(payload));
    },
    getRegisteredBotRequest: (payload) => {
      dispatch(getRegisteredBotRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BotManagementRegistered);

