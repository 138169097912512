import React from 'react';
// import Subscribe from '../../components/MercurXSubscribe/Subscribe';
//import HomeSales from '../../components/MercurXHomeSales/HomeSales';
import './Home.scss';
// import MeatTeam from '../../components/MercurXMeetTeam/MeetTeam';
// import MercurXQuickHandler from '../../components/MercurXQuickHandler/MercurXQuickHandler';
// import { quickHandlerImages } from '../../helpers/quickHandlerImages';
import { Container } from 'react-bootstrap';
// import MercurXOfferCards from '../../components/MercurXOfferCards/MercurXOfferCards';
// import MercurXAboutUs from '../../components/MercurXAboutUs/MercurXAboutUs';

function Home({ ...props }) {
  return (
    <Container className="home-container">
      {/* <div className="d-flex flex-column justify-content-center align-items-center mt-5">
        <div className="text-fs-head-lg">Planting Digital Seeds</div>
        <div className="text-fs-body-lg text-t-body-color mb-5">
          A secure and straightforward way to launch and manage tokens for your web3 project.
        </div>
        <MercurXQuickHandler
          isSignUpAndKYC={true}
          signUpAndKYCImg={quickHandlerImages.lpSignUpAndKYCImg}
          isVerifyWallet={true}
          verifyWalletImg={quickHandlerImages.lpVerifyWalletImg}
          isStakeMerxToken={true}
          stakeMerxTokenImg={quickHandlerImages.lpStakeMerxTokenImg}
          registerForSaleImg={quickHandlerImages.lpRegisterForProject}
          href="#launchpad-projects"
          history={props.history}
        />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
        <div className="text-fs-head-lg">What We Offer?</div>
        <div className="text-fs-body-lg text-t-body-color mb-5">
          Our carefully crafted platform helps you create, manage, and optimize your web3 projects
          for success.
        </div>
        <MercurXOfferCards /> 
       </div> */}
      {/* <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
        <div className="text-fs-head-lg">About Us</div>
        <div className="text-fs-body-lg text-t-body-color mb-5">
          <div className="d-flex flex-column justify-content-center align-items-center">
            We believe blockchain is a revolutionary technology capable of transforming
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            the formation, funding, organizations and companies.
          </div>
        </div> */}
        {/* <MercurXAboutUs /> */}
      {/* </div> */}

      {/* <MeatTeam /> */}
      {/* <Subscribe /> */}
    </Container>
  );
}

export default Home;
