import React from 'react';
import './MercurXBackButton.scss';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';

function MercurXBackButton({ ...props }) {
  const { buttonText } = props;
  const history = useHistory();

  function backToList() {
    history.goBack();
  }

  return (
    <div className="cursor-pointer d-flex align-items-center" onClick={backToList}>
      <MercurXIcons name="BsArrowLeftShort" size={24} color={mainColors.primary} />
      <span className="mercurx-back-button-text text-fs-body-md font-weight-bold">
        {buttonText}
      </span>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXBackButton);
