import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './Subscribe.scss';

function Subscribe() {
  return (
    <>
      <div className="subscribe mt-4">
        <Row>
          <Col className="Subscribe-title">Subscribe For Newsletter</Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8} md={8} className="Subscribe-text">
            {/* Embark on the adventure of a lifetime - sign up now and sail through the thrilling
            waters of the MercurX Universe, where every newsletter is a treasure chest waiting to be
            unlocked! */}
          </Col>
          <Col></Col>
        </Row>

        <Row className="email-row" style={{ width: '70%' }}>
          <Col>
            <InputGroup className="email-form">
              <Form.Control className="email" type="email" placeholder="Email Address" />

              <Button className="email-button" variant="primary" type="submit">
                Subscribe
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Subscribe;
