import React from 'react';
import './BotManagement.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import BotManagementDetailInputs from '../../components/BotManagementDetailInputs/BotManagementDetailInputs';

function BotManagement({ ...props }) {
  return (
    <div >
      <div  className='custom-back-button'>
      <MercurXBackButton buttonText="Back" />
      </div>
      {/* <div className="text-fs-head-sm">CREATE</div> */}
      <BotManagementDetailInputs isEdit={false} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BotManagement);
