import { doGet, doPost, doPut } from './axiosCrud';

//User
export const login = (body) => doPost('/login', body);
export const signUp = (body) => doPost('/signup', body);
export const forgotPassword = (body) => doPost('/password-recovery', body);
export const resetPassword = (body) => doPost('/reset-password', body);
export const activation = (body) => doPost('/activate', body);
export const logut = (body) => doPost('/login', body);
export const getAccountDetails = (body) => doGet('/user/profile', body);
export const resendVerificationEmail = () => doPost('/user/activation-email');
export const checkUserToken = () => doGet('/user/check_token');
export const transaction = (body) => doPost('/transaction', body);
export const newtorkChain = (chainId) => doPost('/chain/' + chainId);
export const getBot = () => doGet('/bot');
export const getBotById = (bot_id) => doGet('/bot/' + bot_id);
export const getRegisteredBot = () => doGet('/registered_bots');
export const register = (body) => doPost('/register', body);
export const registerUpdate = (body) => doPut('/update_registered_bot', body);
export const postBotHistory = (id) => doGet('/history/'+id);
export const postMyBotHistory = (id) => doPost('/user_history/'+id);
export const apiKey = (body) => doPost('/api/add', body);
export const apiKeyDelete = () => doPost('/api/delete');
//Post
export const getPosts = () => doGet('/projects');
export const getPostDetails = (id) =>
  doGet('', {
    params: {
      id: id,
    },
  });

//Projects
export const getProjects = () => doGet('/projects');
export const getProjectByID = (id) => doGet('/projects/' + id);
export const addProject = (body) => doPost('/projects', body);

//Tokens
export const getTokens = () => doGet('/tokens');
export const getTokenByID = (id) => doGet('/tokens/' + id);
export const addToken = (body) => doPost('/tokens', body);

//bscApi
export const GetTxlist = (body) => doPost('/bsc', body);
export const getWalletHistory = (wallet) => doGet('/user/wallet_history/' + wallet);

//bscApi
export const GetAbiList = (body) => doGet('/contracts/abi', body);
export const getBNB = () => doGet('/bnb');

//locking
export const getLockingHistory = (id) => doGet('/lock/' + id);
export const postLocking = (body) => doPost('/lock', body);
export const getLockingStatistics = (wallet) => doGet('/locking/history/' + wallet);
export const putLocking = (id) => doPut('/lock/' + id);
export const getTier = (id) => doGet('/tier/' + id);
export const putTier = (id) => doPut('/tier/' + id);

// //default
export const enable2fa = (userID,totp_code) => doPost('/enable_2fa/' + userID+`/${totp_code}`);
export const getGenerateQR = (userID,body) => doGet('/generate_qr/' + 
userID+`?is_enabled=${body}`);
export const verify2fa = (userID,totp_code) => doPost('/verify_2fa/'
 + userID+`/${totp_code}`);
 export const create2fa = (userID) => doPost('/create_2fa/'+ userID);