import './ApiSettings.scss';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { buyTokenModalAction } from '../../store/token/tokenActions';
import AddNewApiModal from '../../components/AddNewApi/AddNewApiModal';
import binanceMiniIcon from '../../assets/img/logo/binance.png';
import { tierInfo } from '../../helpers/tierInfo';
import {
  accountDetailsRequestAction
} from '../../store/account/userActions';
import {
  apiKeyDeleteRequest
} from '../../store/apiKey/apiKeyActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function ApiSettings({ ...props }) {
  const {
    buyTokenModalRequest,
    tierData,
    accountDetailsRequest,
    user,
    apiKeyDeleteRequest,
    success,
    successDelete,
  } = props;
  const history = useHistory()
  const deleteApi = async () => {
    try {
      await apiKeyDeleteRequest();
    } catch (err) {
    }
  };
  const [stake, setStake] = useState(true);

  const stakeSetup = () => {
    setStake(true);
  };
  const handleShow = () => {
    buyTokenModalRequest(true);
  };
  const [selectedTier, setSelectedTier] = useState(tierInfo?.[tierData?.tier]);
  useEffect(() => {
    if (tierData?.tier !== null && tierData?.tier !== undefined) {
      setSelectedTier(tierInfo?.[tierData?.tier]);

    }
  }, [tierData]);

  useEffect(() => {
    if (props.success || props.successDelete !== null) {
      accountDetailsRequest();
    }
  }, [props.successDelete, props.success]);

  useEffect(() => {
    // This effect will run whenever user.api_key_status changes
    // console.log(user?.api_key_status)
    if (user?.api_key_status !== undefined) {
      accountDetailsRequest();
    }
  }, [user?.api_key_status, accountDetailsRequest]);
  const handleRedirectProfile = () => {
    history.push('/profile#profile');
  };
  return (
    <Container>
      <div className="text-fs-head-sm text-start">Api Settings</div>
      <Row className="mt-5 ">
        {tierInfo.map((item, index) => {
          return (
            <Col key={index} sm={12} md={6} lg={6} className="tier-card ">
              <Card
                className='border-0 py-2 px-3 shadow tier-card'
              // onClick={() => setSelectedTier(tierInfo[index])}
              >
                <img
                  className="img-fluid mx-auto mb-2"
                  alt="binance-mini-icon"
                  src={binanceMiniIcon}
                  width={50}
                />
                <div className="text-fs-head-xs mx-auto mt-3">
                  {item.title}
                </div>
                <div className="text-muted text-center mt-3 standart">
                  {item.standart}
                </div>
                {user ? (
                  item.id !== 1 ? (
                    <Button className="token-button mt-5" disabled>
                      Coming Soon
                    </Button>
                  ) : (
                    user?.is_active !== 'True' ? (
                      <Button
                        className="token-button mt-5"
                        variant="primary"
                        onClick={handleRedirectProfile}>
                        User Not Verified
                      </Button>
                    ) : (
                      user?.api_key_status === false ? (
                        <Button className="token-button mt-5"
                          variant="primary"
                          onClick={() => {
                            stakeSetup();
                            handleShow();
                          }}>
                          Add New API
                        </Button>
                      ) : (
                        <Button className="token-button mt-5"
                          variant="primary"
                          onClick={() => {
                            deleteApi();
                          }}>
                          Delete API
                        </Button>
                      )
                    )
                  )
                ) : null}
              </Card>
            </Col>
          );
        })}
      </Row>
      {stake ? (
        <AddNewApiModal />
      ) : (
        <div></div>
      )}
    </Container>

  );
}

const mapStateToProps = (state) => {
  return {
    tierData: state.tierReducer.tierData,
    user: state.userReducer.user,
    success: state.apiKeyReducer.success,
    successDelete: state.apiKeyReducer.successDelete,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    buyTokenModalRequest: (payload) => {
      dispatch(buyTokenModalAction(payload));
    },
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
    apiKeyDeleteRequest: (creds) => {
      dispatch(apiKeyDeleteRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiSettings);
