import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './MercurXQuickHandler.scss';
// import { checkUserWalletAccount } from '../../helpers/verificationHelper';
// import wallet from '../../helpers/wallet';

function MercurXQuickHandler({ ...props }) {
  const {
    user,
    accounts,
    isSignUpAndKYC,
    signUpAndKYCImg,
    isVerifyWallet,
    verifyWalletImg,
    isStakeMerxToken,
    stakeMerxTokenImg,
    registerForSaleImg,
    history,
    className,
  } = props;

  const [signUpAndKycRef, setSignUpAndKycRef] = useState(null);
  const [verifyWalletRef, setVerifyWalletRef] = useState(null);
  const [stakeMerxTokenRef, setStakeMerxTokenRef] = useState(null);
  const [registerForSaleRef, setRegisterForSaleRef] = useState(null);

  const [size, setSize] = useState([0, 0]);

  function updateSize() {
    setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function getImgStyle(ref, img) {
    const halfHeight = Math.floor(img?.height / 2);
    return {
      position: 'absolute',
      top: ref?.offsetTop - halfHeight,
    };
  }

  return (
    <>
      <Row className={className}>
        {isSignUpAndKYC && (
          <Col
            id="sign-up-and-kyc-col"
            ref={(newRef) => setSignUpAndKycRef(newRef)}
            className="quick-handler-col"
            lg={isStakeMerxToken ? '4' : '4'}
            md="6"
            sm="12"
          >
            <div className="d-flex justify-content-center align-items-start">
              <img
                alt="sign-up-and-kyc"
                src={signUpAndKYCImg.src}
                style={getImgStyle(signUpAndKycRef, signUpAndKYCImg)}
                width={signUpAndKYCImg.width}
                height={signUpAndKYCImg.height}
              />
            </div>

            <div
              className="bg-white rounded pb-5 px-4 mx-1 shadow 
              quick-handler-container d-flex flex-column justify-content-end"
            >
              <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
                STARTER
              </div>
              <div className="h6 mb-0 text-center text-t-body-color text-fs-head-xs">
              0 ₺
              </div>
              <div className="h6 mb-0 text-center text-t-body-color text-fs-head-xs">
                30 days
              </div>
              <div className="my-2 text-center" style={{ borderTop: '2px solid #F3E20F' }}></div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body ">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                50 Number of simultaneous transactions
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                50 Transaction Limit per day
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'red', fontSize: '1.1em' }}>- </span>
                Scanning
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="d-flex justify-content-center border-top-0">
                <Button
                  className="text-fs-head-sm py-2"
                  variant="primary"
                // disabled={user}
                // onClick={() => {
                //   if (!user) {
                //     history.push('/signup');
                //   }
                // }}
                >
                  {user ? 'Buy' : 'Buy'}
                </Button>
              </div>
            </div>
          </Col>
        )}
        {isVerifyWallet && (
          <Col
            id="verify-wallet-col"
            ref={(newRef) => setVerifyWalletRef(newRef)}
            className="quick-handler-col"
            lg={isStakeMerxToken ? '4' : '4'}
            md="6"
            sm="12"
          >
            <div className="d-flex justify-content-center align-items-start">
              <img
                alt="sign-up-and-kyc"
                src={signUpAndKYCImg.src}
                style={getImgStyle(signUpAndKycRef, signUpAndKYCImg)}
                width={signUpAndKYCImg.width}
                height={signUpAndKYCImg.height}
              />
            </div>

            <div
              className="bg-white rounded pb-5 px-4 mx-1 shadow 
              quick-handler-container d-flex flex-column justify-content-end"
            >
              <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
                PREMIUM
              </div>
              <div className="h6 mb-0 text-center text-t-body-color text-fs-head-xs">
                999 ₺
              </div>
              <div className="h6 mb-0 text-center text-t-body-color text-fs-head-xs">
                30 days
              </div>
              <div className="my-2 text-center" style={{ borderTop: '2px solid orange' }}></div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body ">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                50 Number of simultaneous transactions
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                50 Transaction Limit per day
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'red', fontSize: '1.1em' }}>- </span>
                Scanning
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="d-flex justify-content-center border-top-0">
                <Button
                  className="text-fs-head-sm py-2"
                  variant="primary"
                // disabled={user}
                // onClick={() => {
                //   if (!user) {
                //     history.push('/signup');
                //   }
                // }}
                >
                  {user ? 'Buy' : 'Buy'}
                </Button>
              </div>
            </div>
          </Col>
        )}
        {isStakeMerxToken && (
          <Col
            id="stake-merx-token-col"
            ref={(newRef) => setStakeMerxTokenRef(newRef)}
            className="quick-handler-col"
            lg={isStakeMerxToken ? '4' : '4'}
            md="6"
            sm="12"
          >
            <div className="d-flex justify-content-center align-items-start">
              <img
                alt="sign-up-and-kyc"
                src={stakeMerxTokenImg.src}
                style={getImgStyle(stakeMerxTokenRef, stakeMerxTokenImg)}
                width={stakeMerxTokenImg.width}
                height={stakeMerxTokenImg.height}
              />
            </div>

            <div className="d-flex justify-content-center align-items-start">
              {/* <img
                alt="sign-up-and-kyc"
                // src={signUpAndKYCImg.src}
                style={getImgStyle(signUpAndKycRef, signUpAndKYCImg)}
                width={signUpAndKYCImg.width}
                height={signUpAndKYCImg.height}
              /> */}
            </div>

            <div
              className="bg-white rounded pb-5 px-4 mx-1 shadow 
              quick-handler-container d-flex flex-column justify-content-end"
            >
              <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
              ELITE
              </div>
              <div className="h6 mb-0 text-center text-t-body-color text-fs-head-xs">
                1.499 ₺
              </div>
              <div className="h6 mb-0 text-center text-t-body-color text-fs-head-xs">
                30 days
              </div>
              <div className="my-2 text-center" style={{ borderTop: '2px solid green' }}></div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body ">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                50 Number of simultaneous transactions
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                50 Transaction Limit per day
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'red', fontSize: '1.1em' }}>- </span>
                Scanning
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="py-1 text-t-body-color
               text-start text-fs-body-sm quick-handler-body">
                <span style={{ color: 'green', fontSize: '1.1em' }}>+ </span>
                 Create 1 Real / 1 Virtual Bot
              </div>
              <div className="d-flex justify-content-center border-top-0">
                <Button
                  className="text-fs-head-sm py-2"
                  variant="primary"
                // disabled={user}
                // onClick={() => {
                //   if (!user) {
                //     history.push('/signup');
                //   }
                // }}
                >
                  {user ? 'Buy' : 'Buy'}
                </Button>
              </div>
            </div>
          </Col>
        )}
        {/* <Col
          id="register-for-sale-col"
          ref={(newRef) => setRegisterForSaleRef(newRef)}
          className="quick-handler-col"
          lg={isStakeMerxToken ? '3' : '4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={registerForSaleImg.src}
              style={getImgStyle(registerForSaleRef, registerForSaleImg)}
              width={registerForSaleImg.width}
              height={registerForSaleImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow  
            quick-handler-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
              REGISTER FOR SALE
            </div>
            <div className="py-3 text-t-body-color text-center text-fs-body-md quick-handler-body">
              During registration, confirm your intent to join. After it ends, no new registrations
              are accepted until the next sale.
            </div>
            <div className="d-flex justify-content-center border-top-0">
              <Button
                className="text-fs-head-sm py-2"
                variant="primary"
                onClick={() => {
                  history.push('/sales');
                }}
              >
                Active Sales
              </Button>
            </div>
          </div>
        </Col> */}
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
  };
}

export default connect(mapStateToProps)(MercurXQuickHandler);
