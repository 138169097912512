import React, { useEffect } from 'react';
import './Marketplace.scss';
import { Button, Container, Col, Card, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getBotRequestAction,
  getRegisteredBotRequestAction,
  registerRequest
} from '../../store/bot/botActions';
import {
  LineChart, Line,
  CartesianGrid
} from 'recharts';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
// import { mainColors } from '../../helpers/colors';
// import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import {
  accountDetailsRequestAction
} from '../../store/account/userActions';

const data = [
  { name: 'a', uv: 12, pv: -10 },
  { name: 'b', uv: 19, pv: 0 },
  { name: 'c', uv: 30, pv: 5 },
  { name: 'c', uv: 30, pv: 3 },
  { name: 'c', uv: 30, pv: 8 },
  { name: 'c', uv: 30, pv: 20 },
  { name: 'c', uv: 30, pv: 1 },
];

function Marketplace({ ...props }) {
  const {
    botData,
    registeredBotData,
    getBotRequest,
    getRegisteredBotRequest,
    setLoadingFull,
    registerRequest,
    user,
    accountDetailsRequest,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getBotRequest();
  }, [getBotRequest]);
  useEffect(() => {
    accountDetailsRequest();
  }, []);
  useEffect(() => {
    getRegisteredBotRequest();
  }, [getRegisteredBotRequest]);

  const botDataArray = botData ? Object.values(botData) : [];


  const routeStrategie = async (e, id) => {
    history.push(`/general-settings/bot-management/${id}`);
    // e.preventDefault();
    // setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    // const bot_id = id;
    // try {
    //   const payload = {
    //     bot_id
    //   };
    //   await registerRequest(payload);
    //   Swal.fire({
    //     icon: 'success',
    //     iconColor: mainColors.primary,
    //     text: 'Registration successful',
    //     confirmButtonColor: mainColors.primary,
    //   });
    //   getRegisteredBotRequest();
    //   setLoadingFull({ isLoading: false });
    // } catch (err) {
    //   Swal.fire({
    //     icon: 'warning',
    //     iconColor: '#E40039',
    //     confirmButtonColor: '#E40039',
    //     text: err.message,
    //   });
    //   setLoadingFull({ isLoading: false });
    // }
  };
  const redirectApiKey = async () => {
    history.push('/general-settings/api-settings');
  };
  const handleCardClick = (id) => {
    history.push(`/bot-detail/${id}`);
  };

  return (
    <Container>
      <div className="text-fs-head-sm text-start">Strategies</div>
      <div className="border-0 mt-4 position-relative">
        <Row className="mb-5">
          {botDataArray.map((item, index) => (
            <Col key={index} sm={12} md={4}>
              <Card className="marketplace-card py-2 px-3 shadow cursor-pointer"
                onClick={() => handleCardClick(item.id)}>
                <div className="text-fs-head-xs mx-auto">{item.name}</div>
                <div className="d-flex justify-content-center align-items-center">
                  {item.status ? 'Active' : 'Inactive'}
                </div>
                <hr />
                <LineChart width={285} height={200} data={data}>
                  <CartesianGrid stroke="#0000" strokeDasharray="5 5" />
                  <Line type="monotone" dataKey="pv" stroke="#097875" />
                </LineChart>
                <div style={{ height: '150px' }}>
                  <div className="text-primary">Key Features</div>
                  <div className="text-fs-body-md">
                    • Creator: {item.creator}
                  </div>
                  <div className="text-fs-body-md">
                    • Created Date: {new Date(item.cdate).toLocaleString()}
                  </div>
                  <div className="text-fs-body-md">
                    • Updated Date: {new Date(item.udate).toLocaleString()}
                  </div>
                </div>
                <hr />
                <Row>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!user?.api_key_status) {
                        redirectApiKey();
                      } else {
                        routeStrategie(e, item.id);
                      }
                    }}
                    disabled={user?.api_key_status &
                      registeredBotData?.bots.some(bot => bot.bot_id === item.id)}
                    className={'btn btn-primary'}
                  >
                    <span className="ms-1">
                      {!user?.api_key_status ? 'Add API Key' :
                        registeredBotData?.bots.some(bot => bot.bot_id === item.id) ? 
                        'Registered' : 'Start'}
                    </span>
                  </Button>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    botData: state.botReducer.botData,
    registeredBotData: state.botReducer.registeredBotData,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBotRequest: (payload) => {
      dispatch(getBotRequestAction(payload));
    },
    getRegisteredBotRequest: (payload) => {
      dispatch(getRegisteredBotRequestAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    registerRequest: (creds) => {
      dispatch(registerRequest(creds));
    },
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
