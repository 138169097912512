import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './apiKeyActionTypes';
import * as actions from './apiKeyActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
// import Swal from 'sweetalert2';

function* apiKeySaga({ creds }) {
  try {
    console.log(creds)
    const { data } = yield call(endpoints.apiKey, creds);
    console.log(data)
    yield put(actions.apiKeyData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Api Key Added!',
        variant: 'success',
      })
    );
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.apiKeyError(e));
  }
}
function* apiKeyDeleteSaga({ creds }) {
  try {
    const { data } = yield call(endpoints.apiKeyDelete);
    console.log(data)
    yield put(actions.apiKeyDeleteData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Api Key Deleted!',
        variant: 'success',
      })
    );
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.apiKeyDeleteError(e));
  }
}

//Watcher Sagas
function* watchApiKey() {
  yield takeEvery(types.APIKEY_REQUEST, apiKeySaga);
}
function* watchApiKeyDelete() {
  yield takeEvery(types.APIKEY_DELETE_REQUEST, apiKeyDeleteSaga);
}



export function* apiKeySagas() {
  yield all([watchApiKey(),watchApiKeyDelete()]);
}
