import { takeEvery, put, all, call,take } from 'redux-saga/effects';
import * as types from './userActionTypes';
import * as actions from './userActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
import Swal from 'sweetalert2';
import { errorHandler } from '../../helpers/errorHandler';

function* loginSaga({ creds }) {
  try {
    var formData = new FormData();
    console.log(formData)
    formData.append('username', creds.data['username']);
    formData.append('password',  creds.data['password']);
    const { data } = yield call(endpoints.login, formData);
    console.log(data)
    yield put(actions.loginData(data));
    if (data.two_factor_auth === true) {
      console.log('data')
      console.log(data)
      yield call(startVerify2faSagaAction, data, creds);
    } else {
      yield call(processLoginWithout2FA, data, creds.history);
    }
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.loginError(e));
  }
}
function* processLoginWithout2FA(data, history) {
  console.log(data)
  yield put(actions.loginData(data));
  yield put(
    alert.setAlertAction({
      title: 'Success!',
      text: 'User login completed successfully!',
      variant: 'success',
    }),    
  );
  const user = {
    user: data,
    token: data['access_token'],
  };
  localStorage.setItem('user', JSON.stringify(user));
  setTimeout(() => {
    history.push('/');
  }, 1000);
}
function* startVerify2faSagaAction(loginData, loginPayload) {
  yield put(actions.loginData(loginData));
  yield put(alert.setAlertAction({ title: 'Info', 
  text: 'Please enter verification code.', variant: 'info' }));
  const verificationCodeAction = yield take('ENTER_VERIFICATION_CODE_ACTION');
  console.log('verificationCodeAction' + verificationCode)
  const verificationCode = verificationCodeAction.payload.code;

  yield call(verify2faSaga, {
    payload: {
      userId: loginData.userId,
      totp_code: verificationCode,
      is_enabled: loginData.is_enabled,
      loginPayload: loginPayload,
      history: loginData.history, 
      username: loginData.username, 
      password: loginData.password, 
    },
  });
}
function* verify2faSaga({ payload }) {
  try {
    const { userId, totp_code, is_enabled, loginPayload,history } = payload;
    const { username, password } = loginPayload;
 
    const { data } = yield call(endpoints.verify2fa, userId, totp_code, is_enabled);

    if (data.valid) {
      yield put(
        alert.setAlertAction({
          title: 'Success!',
          text: 'Two-factor authentication successfully verified!',
          variant: 'success',
        }),        
      );

      
      yield put(actions.verify2faErrorAction(data));
      yield call(processLoginAfter2FA, loginPayload, history);
      console.log(loginPayload)
    } else {
      yield put(
        alert.setAlertAction({
          title: 'Error!',
          text: data.message,
          variant: 'danger',
          outTimeMS: 6000,
        }),
      );
      yield put(actions.verify2faErrorAction(data.message));
    }
  } catch (e) {
   yield put(
     alert.setAlertAction({
       title: 'Error!',
       text: e.msg,
       variant: 'danger',
       outTimeMS: 6000,
     }),
   );
    yield put(actions.verify2faErrorAction(e));
  }
}
function* processLoginAfter2FA( loginPayload, history) {
  console.log(loginPayload)
  console.log(history)
  var formData = new FormData();
  formData.append('username', loginPayload.username);
  formData.append('password', loginPayload.password);
  const { data } = yield call(endpoints.login, formData);
  console.log('197,: data')
  console.log(data)
  yield put(actions.loginData(data));
  yield put(
    alert.setAlertAction({
      title: 'Success!',
      text: 'User login completed successfully!',
      variant: 'success',
    }),    
  );
  const user = {
    user: data,
    token: data['access_token'],
  };
  localStorage.setItem('user', JSON.stringify(user));
  setTimeout(() => {
    history.push('/');
  }, 0)
}
function* forgotPasswordSaga({ creds }) {
  try {
    var formData = new FormData();
    formData.append('email', creds['email']);

    const { data } = yield call(endpoints.forgotPassword, formData);

    yield put(actions.forgotPasswordData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Valid Mail Adress! Check your mail for password reset adress...',
        variant: 'success',
      }),
    );
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.forgotPasswordError(e));
  }
}
function* resetPasswordSaga({ creds }) {
  try {
    var formData3 = new FormData();

    formData3.append('password', creds['password']);
    formData3.append('confirmPassword', creds['confirmPassword']);
    formData3.append('resetToken', creds['resetToken']);
    const body = { token: creds['resetToken'], new_password: creds['password'] };

    const { data } = yield call(endpoints.resetPassword, body);
    yield put(actions.resetPasswordData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Your password has been reset successfully! Redirecting to Login Page',
        variant: 'success',
      }),
    );
    setTimeout(() => {
      creds.history.push('/login');
    }, 2000);
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.resetPasswordError(e));
  }
}
function* activationSaga({ creds }) {
  try {
    var formData3 = new FormData();

    formData3.append('activationCode', creds['activationCode']);
    formData3.append('activationToken', creds['activationToken']);
    const body = { token: creds['activationToken'], activation_code: creds['activationCode'] };

    const { data } = yield call(endpoints.activation, body);
    yield put(actions.activationData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Your account has been activated successfully! Redirecting to Home Page',
        variant: 'success',
      }),
    );
    yield put(actions.accountVerifiedAction(true));
    setTimeout(() => {
      creds.history.push('/');
    }, 3000);
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.activationError(e));
  }
}

function* logoutSaga() {
  const deneme = localStorage.removeItem('user');
  console.log(deneme)
  yield put(actions.logoutData());
}

function* signUpSaga({ creds }) {
  try {
    var formData = new FormData();
    formData.append('username', creds.data['username']);
    formData.append('password', creds.data['password']);
    formData.append('email', creds.data['email']);
console.log(formData['username'])
console.log(formData)
    const { data } = yield call(endpoints.signUp, formData);
    console.log(data)
    yield put(actions.signUpData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'User Signed Up!',
        variant: 'success',
      }),
    );
    creds.history.push('/login');
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.signUpError(e));
  }
}

function* getAccountDetailsSaga({ creds }) {
  try {
    const { data } = yield call(endpoints.getAccountDetails);
    yield put(actions.accountDetailsDataAction(data));
  } catch (e) {
    errorHandler(e);
    yield put(actions.accountDetailsErrorAction(e));
  }
}

function* resendVerificationEmail({ payload }) {
  try {
    console.log(payload)
    const { data } = yield call(endpoints.resendVerificationEmail);
    yield put(actions.resendVerificationEmailDataAction(data));
    console.log(data)

    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: "Verification Email Sent! Don't forget to check your spam/junk folder.",
    });
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.error,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.resendVerificationEmailErrorAction(e));
  }
}

// function* checkUserToken({ payload }) {
//   try {
//     const { data } = yield call(endpoints.checkUserToken);
//     yield put(actions.checkUserTokenDataAction(data));
//   } catch (e) {
//     errorHandler(e);
//     yield put(actions.checkUserTokenErrorAction(e));
//   }
// }
function* checkUserToken({ payload }) {
  try {
    const { data } = yield call(endpoints.checkUserToken);
    if (!data) {
      throw new Error('Invalid token');
    }
    yield put(actions.checkUserTokenDataAction(data));
  } catch (e) {
    errorHandler(e);
    yield put(actions.checkUserTokenErrorAction(e));
  }
}

function* enable2faSaga({ payload  }) {
  try {
    const { userId, totp_code } = payload;
    console.log(payload)
    const { data } = yield call(endpoints.enable2fa, userId, totp_code);
    console.log(data)
    if (data) {
      yield put(actions.enable2faDataAction(data));
      console.log(data)
      yield put(
        alert.setAlertAction({
          title: 'Success!',
          text: 'Two-factor authentication is updated successfully!',
          variant: 'success',
        })
      );
    } else {
      yield put(
        alert.setAlertAction({
          title: 'Error!',
          text: data.error,
          variant: 'danger',
          outTimeMS: 6000,
        })
      );
      yield put(actions.enable2faErrorAction(data.error));

    }
  } catch (e) {
    errorHandler(e);
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e.msg,
        variant: 'danger',
        outTimeMS: 6000,
      })
    );
    yield put(actions.enable2faErrorAction(e));
  }
}

function* generateQrSaga({ payload }) {
  try {
    const { userId, body } = payload;
    console.log('payloadQR')
    console.log(payload)
    const { data } = yield call(endpoints.getGenerateQR,userId, body);
    console.log('QR')
    console.log(data)
    yield put(actions.generateQrDataAction(data));
  } catch (e) {
    console.log(e)
    yield put(actions.generateQrErrorAction(e));
  }
}
function* create2faSaga({ payload }) {
  try {
    const { userId } = payload ;
    const { data } = yield call(endpoints.create2fa,userId);
    console.log('QR')
    console.log(data)
    yield put(actions.create2faErrorAction(data));
  } catch (e) {
    console.log(e)
    yield put(actions.create2faErrorAction(e));
  }
}

function* watchVerify2fa() {
  yield takeEvery(types.VERIFY_2FA_REQUEST, verify2faSaga);
}
function* watchCreate2fa() {
  yield takeEvery(types.CREATE_2FA_REQUEST, create2faSaga);
}
function* watchEnable2fa() {
  yield takeEvery(types.ENABLE_2FA_REQUEST, enable2faSaga);
}
function* watchGenerateQr() {
  yield takeEvery(types.GENERATE_QR_REQUEST, generateQrSaga);
}

function* watchLoginUser() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
}

function* watchLogoutUser() {
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
}

function* watchSignUpUser() {
  yield takeEvery(types.SIGN_UP_REQUEST, signUpSaga);
}
function* watchForgotPassword() {
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
}
function* watchResetPassword() {
  yield takeEvery(types.RESET_PASSWORD_REQUEST, resetPasswordSaga);
}
function* watchActivation() {
  yield takeEvery(types.ACTIVATION_REQUEST, activationSaga);
}
function* watchAccountDetails() {
  yield takeEvery(types.ACCOUNT_DETAILS_REQUEST, getAccountDetailsSaga);
}
function* watchResendVerificationEmail() {
  yield takeEvery(types.RESEND_VERIFICATION_EMAIL_REQUEST, resendVerificationEmail);
}
function* watchCheckUserToken() {
  yield takeEvery(types.CHECK_USER_TOKEN_REQUEST, checkUserToken);
}

export function* userSaga() {
  yield all([
    watchLoginUser(),
    watchLogoutUser(),
    watchSignUpUser(),
    watchForgotPassword(),
    watchResetPassword(),
    watchActivation(),
    watchAccountDetails(),
    watchResendVerificationEmail(),
    watchCheckUserToken(),
    watchEnable2fa(),
    watchGenerateQr(),
    watchVerify2fa(),
    watchCreate2fa()
  ]);
}
